.DesignContainer{
display: flex;
width: 98%;
height: fit-content;
margin: 0;
padding: 0;
background-image: url('./images/Lines.svg');
background-repeat: no-repeat;
background-position: bottom;
background-size: 90vw;
overflow-x: hidden;
}
.DesignContentContainer{
    width: 70vw;
    margin: auto;
    overflow: hidden;
}
.DesignWrap{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.DesignTextContainer{
    margin: 0;
    padding:0;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.H3Collection{
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 20px;
}
.DesignIllustration{
    height: 25vw;
    width: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
    
}

@media (max-width: 1100px) {
    .DesignContainer{
        
        height: auto;
       
        
        }
    .DesignWrap{
        margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
        gap: 20px;
    
    }
    .DesignIllustration{
        height: auto;
        width: 40vw;
        margin: 0;
        padding: 0;
        display: flex;
    }
  }
  @media (max-width: 800px) {
    
    .DesignWrap{
        margin: 0;
    padding: 0;
    
    }

  
  }
  
