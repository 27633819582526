.NavBar-contaner {

    height: 70px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, .3);
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    position: fixed;
    z-index: 2;

}

.linksContainers {
    max-width: 1500px;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 40vw;

}

.NavPages {
    width: fit-content;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 1.5em;

}

.LogoLink {
    height: 100%;
    width: fit-content;
    padding: 0;
    margin: 0;
    



}

.NavLogo {
   
    height: 50px;
    width: auto;
   
   margin: 10px;
   
}

.NavPages a {
    width: fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    padding: 0;



}


.NavLan {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin: 0;
    padding: 0;
    align-items: center;

}

.NavLan p {
    margin: auto 0;
    padding: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.NavLan a {
    margin: auto 0;
    padding: 0;
    cursor: pointer;

}

.dropDownNav {
    position: absolute;

}


/*responsive*/
@media (min-width: 1000px) {
    .dropDownNav {
        display: none;


    }


}

@media (max-width: 1000px) {
    .NavBar-contaner {

        height: auto;
        width: 100vw;
        margin: 0;
        padding: 0;
        display: flex;

        position: fixed;
        z-index: 2;

    }

    .linksContainers {
        display: none;
    }

    .dropDownNav {

        left: 0;
        top: 0;
        width: 100%;
        height: fit-content;
        padding: 0;
        margin: 0;

        display: flex;
        flex-direction: column;
        overflow: hidden;
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, .2);
        
        box-sizing: border-box;
    }
    .NavLogo {
   position: absolute;
        height: 50px;
        width: auto;
       align-self: center;
       margin: 10px;
       
    }

    .LogoToggle {
        cursor: pointer;
        height: auto;
        width: auto;
       margin: 20px;
    }
    .MenuIcon{
        height: 35px;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }
    .MenuIcon:hover{
        transform: scale(1.05);
        transition: 0.3s;
        
    }
    .MenuIcon:active{
        transform: scale(0.95);
        transition: 0.3s;
        filter: invert(79%) sepia(1%) saturate(0%) hue-rotate(338deg) brightness(86%) contrast(84%);
    }

    .NavDropWrap {
        position: absolute;
       
        display: flex;
        height: 0;
        width: auto;
        
        overflow: hidden;
        transition: 0.5s ease;
        /* Adjust the transition property */

    }
    .NavDropWrap nav{
        display: none;
    }

    .NavDropWrapActive {
        display: flex;
        

        height: 92vh;
        transition: 0.5s ease;
        /* Adjust the transition property */
    }

    .NavPagesDrop {
        margin: 20px;
        display: flex;
        flex-direction: column;
        gap: 2em;
        height: 100%;
        width: 200px;
        

       

    }

    .NavPagesDrop a {
        margin: 0;
        padding: 0;

    }

    .NavLanDrop {
        display: flex;
        flex-direction: row;
        gap: 1em;
        position: absolute;
        left: 20px;
        bottom: 100px;
        justify-content: center;

    }

    .NavLanDrop p {
        margin: auto 0;
        padding: 0;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .NavLanDrop a {
        margin: auto 0;
        padding: 0;
        cursor: pointer;
        font-size: 20px;

    }

}