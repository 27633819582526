
.CorazonWrap{
  margin: auto;
  padding: 0;

}
.Corazon {
  margin: auto;
  padding: 0;
  display: flex;
  scale: 1.1
;

}
@media (max-width: 1100px) {
  .Corazon {
    transform: scale(0.8);
    margin: auto;
  }
}
@media (max-width: 800px) {
  .Corazon {
    transform: scale(0.5);
    margin: auto;
  }
}
@media (max-width: 600px) {
  .Corazon {
    transform: scale(0.4);

    margin: auto;
  }
}
@media (max-width: 400px) {
    .Corazon {
        transform: scale(0.3);
      
      
  
      margin: auto;
      
    }
  }
