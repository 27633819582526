.FooterContainer{
width: 100%;
height: 250px;
margin: auto;
display: flex;

color: #fff;
align-content: center;
justify-content: center;
}

.PFooter{
    margin: 0 auto;
    text-align: center;
}
