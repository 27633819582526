.wrap {
  
  
  
  padding-bottom: 200px;
  width: 100%;
  display: flex;
  height: 100vh;
  background-image: url(./images/blackBackground.svg);
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;

  justify-items: center;
  align-items: center;
  
  
  
}



/*.logoWrap {
  height: auto;
  width: auto;
  margin: auto;
  display: flex;
  flex-direction: row;
}
.logo {
  color: #eee;
  margin: auto;
  height: 300px;
}
.titleHeader {
  color: #eee;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40;
  line-height: 54px;
  text-align: center;
}*/

/*Media queries*/

@media (max-width: 1100px) {
  .wrap{
    width: 100%;
    
     margin: 0;
     
     
  }
  
    
   }
   @media (max-width: 800px) {
    .wrap{
      width: 100vw;
      height: auto;
      
       margin: 0 ;
       padding: 0;
       align-content: center;
       justify-content: center;
       
    }
    
      
     }
     @media (max-width: 500px) {
      .wrap{
        width: 100vw;
        height: auto;
        
        
         margin: 0 ;
         padding: 0;
         
         
      }
      
        
       }
  
