
.StructureWrap {
    position: relative;
    flex-direction: column;
    margin: auto;
    background: none;
    height: auto;
    padding: 0;
    max-width: 70vw;
    overflow-x: hidden;
  }
  .structure {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
  }
  .TextContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 0;
  }
 
  
  
  .imageWrap {
    max-height: fit-content;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    
  
    background-color: rgba(238, 238, 238, 0.3);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
  
    border-radius: 50px;
    
   
  }
  
  
  .DragonGif {
    margin: 30px;
    margin-bottom: 0;
    height: 500px;
    width: auto;
    border-radius: 40px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }
  .weText{
    margin: 10px 30px;
  }
  .weWork{
    text-align: center;
    margin-top: 50px;
  }
  .portfolio {
    align-items: center;
    background: linear-gradient(60deg,#003b94 -5.53%,#00f0e9 119.09%);
    border: transparent;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    height: 35px;
    justify-content: center;
    margin: auto;
    outline: none;
    text-decoration: none;
    padding: 5px;
    width: 200px;
    
}
.portfolio:hover{
  background: -webkit-linear-gradient(
    45deg,
    rgb(251, 161, 88),
    rgb(87, 0, 109)
  );
  transition: 0.1s;
  overflow: hidden;
  
}
  
  /* Media Queries*/
  @media (max-width: 1300px) {
    /* Adjustments for screens up to 1200px wide */
    #Modeling{
      width: 100%;
    }
    .StructureWrap {
      width: 90vw;
      max-width: 100%;
        

    }
  }
  
  @media (max-width: 1200px) {
    /* Adjustments for screens up to 1200px wide */
    .structure {
      column-gap: 10px;
     display: flex;
     flex-direction: column;
     height: auto;
        
        
      
    }
    .TextContainer {
      gap: 10px;
    }
    .imageWrap {
      padding: 20px;
      
      border-radius: 40px;
    }
    .DragonGif {
      margin: auto;
      
    }
  }
  
  @media (max-width: 900px) {
    /* Adjustments for screens up to 900px wide */
    .imageWrap {
      padding: 20px;
      border-radius: 30px;
      height: auto;
    }
    .DragonGif {
      margin: auto;
      height: 50vw;
    }
    
  }
  
  /* ... More Media Queries ... */
  
  @media (max-width: 600px) {
    /* Adjustments for screens up to 375px wide */
    .StructureWrap {
      
      margin: auto;
      padding: 0;
      max-width: 83vw;
    }
    .structure {
      width: 100%;
      height: auto;
    }
    .TextContainer {
      margin: auto;
    }
    .imageWrap {
      padding: 10px;
      border-radius: 30px;
      margin: auto;
      height: auto;
    }
    .DragonGif {
      height: 75vw;
      margin: auto;
      border-radius: 1.1em;
    }
    .weText{
      width: 90%;
      margin: auto;
    }
  }