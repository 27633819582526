.ContactWrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 100px auto;
    padding: 0;
    height: auto;
    width: 70vw;
  overflow-x: hidden;
   

}
.ContactTitleContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 70%;
    margin: 0 auto ;
    text-align: center;
    
}
.ContactContainer{
    display: flex;
   flex-direction: row;
   margin: 0 auto ;
    gap: 25px;
    width: 100%;
    height: auto;
    
    
    
}
.cCardsContainer{
    margin:  0 auto;
   height: auto;
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 20px;
    /*background-color: rgba(238, 238, 238, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    border-radius: 30px;*/

}
.Whatsappli{
    margin: auto 25px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.ContactCard{
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
    border-radius: 40px;
    vertical-align: middle;
   
    gap: 10px;
    background: linear-gradient(60deg, #003B94 -5.53%, #00F0E9 119.09%);

    transition:1s;
    cursor: pointer;
    align-items: center;
}
.ContactCard:hover{
    transition:0.5s;
    background: -webkit-linear-gradient(
    45deg,
    rgb(251, 161, 88),
    rgb(87, 0, 109)
  );
  

}
.MailWrap{
    padding: 0;
    margin: auto;
    width: 100%;
    height: 90px;
    display: flex;
    border-radius: 40px;
    background: linear-gradient(60deg, #003B94 -5.53%, #00F0E9 119.09%);
    vertical-align: middle;
}
.mailContentWrap{
    
    margin: auto 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    
    vertical-align: middle;
    
}
.contactIcon{
    height: 40px;
    color: white;
    cursor: pointer;
}
    

.contactForm{
   
    height: auto;
    padding: 0;
    width: 100%;
    margin: auto;
   
    display: grid;
    grid-template-columns: auto;
    gap: 15px;
   
    

}
textarea{
    margin: 0;
    resize: none;
    border: none;
    padding: 10px;
    border-color: transparent;
    outline: none;
    color: #fff;
    
    
    
    
    background-color: rgba(238, 238, 238, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
textarea::placeholder {
    color: #fff;
  }




input{
    margin: 0;
    height: 35px;
    resize: none;
    border-radius: 0.5rem;
    border: none;
    padding: 5px;
    border-color: transparent;
    color: #fff;
    overflow: auto;
    outline: none;
    background-color: rgba(238, 238, 238, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    
    
   
}
input::placeholder {
    color: #fff;
  }
.btn{
    
    border-radius: 0.5rem;
    border: none;
    padding: 5px;
    border-color: transparent;
    outline: none;
    width: 200px;
    height: 35px;
    margin: auto 0;
    color: #fff;
    font-weight: 600;
    background: linear-gradient(60deg, #003B94 -5.53%, #00F0E9 119.09%);
    cursor: pointer;

   
}
.btn:hover{
scale: 103%;
transition:0.5s;
background: -webkit-linear-gradient(
    45deg,
    rgb(251, 161, 88),
    rgb(87, 0, 109)
  );;
}
.btn:active{
    background: rgb(255, 177, 82);

}

@media (max-width: 1200px) {
    .ContactWrap{
        
        width: 90%;
        margin: auto;
       margin-top: 20px;
    
    }
    .ContactTitleContainer{
        margin-top: 100px ;
        width: 90%;
       
        
    }
    .ContactContainer{
        display: flex;
       flex-direction: column;
       
       margin: 0 auto ;
        gap: 25px;
        width: 100%;
        height: auto;
        
        
        
    }
    .ContactCard{
        margin: auto;
        width: 100%;
        height: 50px;
        border-radius: 30px;
        vertical-align: middle;
        justify-content: left;
        align-items: center;
        
       
        
    
    }
    .MailWrap{
        padding: 0;
        margin: 0;
        width: 100%;
        height: 50px;
        display: flex;
       
        border-radius: 30px;
        
    }
    .mailContentWrap{
        margin: auto;
        padding: 0 15px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        vertical-align: middle;
        justify-content: left;
        align-items: center;
    }
    .Whatsappli{
        margin: auto 0;
        padding: 0 15px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        vertical-align: middle;
        justify-content: left;
        align-items: center;
    }
    .contactIcon{
        margin: 0;
        height: 30px;
        color: white;
    }
  }
  