
.RenderingContainer {
  display: flex;

 
  height: 80vh;
  margin: auto;
  background-image: url("./images/Vectorrecwaves.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover ;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: cover;
  width: 100%;

}

.RenderingWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  width: 70vw;
}


.RImageContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.Render {
  height: 470px;
  width: auto;
  border-radius: 30px;
}
.Render:hover {
  scale: 105%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
}
.RenderingTextWrap {
  height: auto;
  width: auto;
}
@media (max-width: 1400px) {
  .RenderingContainer {
    width: 100vw;
    height: 60vh;
  }

  .RImageContainer {
    gap: auto;
  }
  .Render {
    height: 340px;
    width: auto;
    border-radius: 30px;
  }
}
@media (max-width: 900px) {
  

  .Render {
    height: 300px;
    width: auto;
  }
}
@media (max-width: 800px) {
  .RenderingContainer {
    
    width: 100%;
    padding: 50px 0;
    height: auto;
  }
  .RenderingWrap {
    width: 70vw;
    display: flex;
    padding: 0;
    margin: auto;
  }
  .RImageContainer {
    width: 90vw;
    
    align-items: center;
    display: grid;
    gap: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    
  }
  .Render {
    width: 30vw;
    height: auto;
    transition: none;
    margin: 0;
    

  }
  .RenderingTextWrap {
    
    
    width: 100%;
    height: auto;
    
    padding: 0;
    margin: auto;
  }
}
@media (max-width: 600px) {
   
   
  .RImageContainer {
    width: 100%;
    
  
    
  }
   
    .RImageContainer::-webkit-scrollbar {
      display: none;

    }
    .Render{
      width: 45vw;
    }
    .RenderingTextWrap {
    
    
        width: 100%;
        height: auto;
        padding: 0;
        margin: auto;
      }
  }
