.AnimationContainer {
  width: 70vw;
  display: flex;

  margin: auto;
  height: fit-content;
  
}
.AnimationCard {
  display: flex;
  width: 100%;
  max-height: fit-content;
  margin: auto;
  padding: 0;
  background-color: rgba(238, 238, 238, 0.243);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  border-radius: 50px;
}
.AllContetWrap {
  height: auto;
  width: 100%;
  margin: 0;
  padding: 50px;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.ATextWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
}
.H2Animation {
  background: -webkit-linear-gradient(
    45deg,
    rgb(251, 161, 88),
    rgb(87, 0, 109)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px;
  font-weight: 800;
  line-height: 90%;
  margin: 0;
}

.AnimationWrap {
  display: flex;

  width: 100%;
  height: 100%;
  margin: 0;
}
.AnimationGif {
  margin: auto;
  height: 450px;
  width: auto;
  border-radius: 40px;
}


@media (max-width: 1200px) {
  .AnimationContainer {
   
    height: 100%;
    width: 85vw;
  }
  .AllContetWrap {
        
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;
    gap: 20px;
    padding: 20px;
  }
  .AnimationCard{
    border-radius: 5vw;
    height: fit-content;
  }
  

  .AnimationGif {
    margin: auto;
    height: 60vw;
    width: auto;
    border-radius: 40px;
  }
}
@media (max-width: 900px) {
    
   
      
  
    .AnimationGif {
      margin: auto;
      height: 70vw;
      width: auto;
      border-radius: 10%;
    }
  }
  @media (max-width: 900px) {
    
   
      
  
    .H2Animation{
      font-size: 10vw;
    }
  }
 