* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, OpenSans, sans-serif;
  scroll-behavior: smooth;
}

::-webkit-resizer {
  display: none;
}

body {
  overflow-x: hidden; /* Hide the horizontal scrollbar */
}


.App {
  overflow: hidden; /* Hide both vertical and horizontal scrollbars */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: auto;
  background: linear-gradient(1.05deg, #000 18%, #002491 92%);
  background-attachment: fixed;
  
}
.En{
  overflow: hidden; /* Hide both vertical and horizontal scrollbars */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: auto;
  background: linear-gradient(1.05deg, #000 18%, #002491 92%);
  background-attachment: fixed;
  display: grid;
  grid-template-rows: auto;
  gap: 70px;
}


.INavBar{
  
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin: auto;
  padding-right: 200px;
  
  width: 100%;
  align-self: right;
  align-items: center;
  justify-content: right;
  
  background: #000;
}
.INavBar p{
  padding: 3px 0;
}
.INavBar a{
  padding: 3px;
}

h1 {
  color: #fff;
  margin: 0;
  font-size: 100px;
  line-height: 90px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  font-size: 50px;
  font-weight:bold;
  color: #fff;
  margin: 0;
  padding: 0;
  display: block;
}

h4 {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  padding: 0;
  display: block;
}

p {
  font-size: 15px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  padding: 0;
  display: block;
}

a {
  background: transparent;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.9rem;
  text-decoration: none;
  cursor: pointer;
}
a:hover{
  text-shadow: 0 0 10px 5px rgba(0,0,0,.2);
color: #009696;
transform: scale(1.05);
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

a:active {
  color: #a7a7a7;
  text-decoration: none;
  transform: scale(0.95);
  transition: 0.5s;
  
}



@media (max-width: 1200px) {
  /* Adjustments for screens up to 900px wide */
  h1 {
    font-size: 5vw;
    line-height: 4vw;
  }
  h4 {
    font-size: 3vw;
  }
  h3 {
    font-size: 4vw;
  }
  p{
    font-size: 10px;
  }
}
@media (max-width: 1200px) {
  /* Adjustments for screens up to 900px wide */
  h1 {
    font-size: 8vw;
    line-height: 7vw;
  }
  h4 {
    font-size: 4vw;
  }
  h3 {
    font-size: 6vw;
  }
  p{
    font-size: 15px;
  }
}
/* ... More Media Queries ... */
