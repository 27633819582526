.BLueWrap{
width: 70vw;
height: 100%;
min-height: 100vh;
margin: 100px auto;
padding: 0;
display: flex;
flex-direction: row-reverse;
gap: 50px;

}
.BluecastContent{
width: auto;
height: fit-content;
margin: 0;
padding: 0;
display: flex;
flex-direction: column;
gap: 20px;
}
.BcTitle{
width: auto;
height: fit-content;
margin: 0;
padding: 0;
display: flex;
flex-direction: column;
}
.x5Dragon{
    width: 100%;
    height: auto;
}
.BluecastContent img{
width: 100%;
height: auto;
object-fit: contain;
border-radius: 20px;
}
.BcText{
width: auto;
height: fit-content;
padding: 20px;
margin: 0;
display: flex;
flex-direction: column;
gap: 50px;
background-color: rgba(238, 238, 238, 0.243);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  border-radius: 30px;
}
.BcText span{
    font-weight: 900;
    background: -webkit-linear-gradient(
        45deg,
        #005ce5,
        #00F0E9
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}
.imageDWrap{
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    
}

.BCContactCard{
    margin: 0;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    border-radius: 40px;
    vertical-align: middle;
   
    gap: 10px;
    background: linear-gradient(60deg, #003B94 -5.53%, #00F0E9 119.09%);

    transition:1s;
    cursor: pointer;
}
.Whatsappli{
    margin: 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.BCContactCard:hover{
    transition:0.5s;
    background: -webkit-linear-gradient(
    45deg,
    rgb(251, 161, 88),
    rgb(87, 0, 109)
  );
  

}
@media (max-width: 1200px) {
    .BLueWrap{
        width: 90vw;
        gap: 3vw;
    }
}
@media (max-width: 600px) {
    
   
    .BLueWrap{
        width: 100vw;
        display: flex;
flex-direction: column;
gap: 20px;
       padding: 10px;
    }
  
.BluecastContent{
    width: 100;
}
.BcText{
    width: 100%;
}
.imageDWrap{
    max-width: 100vw;
    overflow-x: hidden;
    overflow-x: scroll;
}
.x5Dragon{
    
    height: 200px;
    width: auto;
    overflow: hidden;
    overflow-x: scroll;
}
.imageDWrap::-webkit-scrollbar{
    background: none;
    
  }
  .imageDWrap::-webkit-scrollbar-thumb{
    background: rgba(238, 238, 238, 0.243);
    border-radius: 10px;
    
  }
  }
  