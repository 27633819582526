.carousel-container {
  display: flex;
  
  margin: auto;
  margin-top: 50px;
  overflow: hidden;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}


.carousel {
  display: flex;
  
  
  
  
}
.carousel-container::-webkit-scrollbar{
  background: none;
  
}
.carousel-container::-webkit-scrollbar-thumb{
  background: rgba(238, 238, 238, 0.243);
  border-radius: 10px;
  
}


.carousel-card {
  position: relative;
  width: 20vw;
  height: 20vw; /* Adjust the height as needed */
 margin: 0.5vw;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.carousel-card:hover{
  margin: 0.5vw;
  cursor: pointer;
  border-radius: 10px;
  scale: 1.02;
  transition: ease-in 0.2s;
}

.carousel-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0; /* Hide the image element */
}

.carousel-card p {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust the background color and opacity */
  color: #fff;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
}

@media (max-width: 600px) {
  .carousel-card {
    width: 40vw;
  height: 40vw;
  }
  
}