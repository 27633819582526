.HomeInfo{
    height: auto;
    width: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.HomeTitle{
    width: 70vw;
    height: auto;
    margin: auto;
    padding: 0;
    border-bottom: 1px solid #fff;
}

@media (max-width: 1200px) {
    /* Adjustments for screens up to 900px wide */
    .HomeInfo{
        
        gap: 90px;
    }
  }
  @media (max-width: 600px) {
    /* Adjustments for screens up to 900px wide */
    .HomeTitle{
        width: 83vw;
        
    }
  }